import React from "react"
import FaCard from "../FaCard"
import "./faCards.scss"

function Cards({ datas }) {
  return (
    <div className="row cards mt-4 mb-4">
      {datas.map(data => (
        <FaCard data={data} key={data.product} />
      ))}
    </div>
  )
}

export default Cards
