import { motion } from "framer-motion"
import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"
import PageHeader from "../../components/PageHeader"
import PageFooter from "../../components/PageFooter"
import FaCards from "../../components/FaCards"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faLandmark,
  faGraduationCap,
  faNotesMedical,
  faCar,
  faShoppingBag,
  faTheaterMasks,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons"
library.add(
  faLandmark,
  faGraduationCap,
  faNotesMedical,
  faCar,
  faShoppingBag,
  faTheaterMasks,
  faHandHoldingUsd
)

function index({ data }) {
  const bannerImg = data.facilitiesJson.headerImg.childImageSharp.fluid
  const desc = data.facilitiesJson.desc
  const items = data.allFacilityDetailsJson.nodes

  return (
    <Layout>
      <Helmet>
        <title>
          Fasilitas | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan
          Terencana
        </title>
        <meta
          name="description"
          content="Grand Wisata Bekasi menawarkan fasilitas seperti sekolah, rumah sakit, pasar modern, area kuliner, Water Park, Celebration Plaza, Club House, serta ruko-ruko dan lot komersial untuk melengkapi kebutuhan para penghuninya."
        />
        <meta
          name="keywords"
          content="grand wisata bekasi,grand wisata,sinar mas land,properti,real estate,rumah"
        />
        <meta name="distribution" content="web" />
        <meta
          property="og:title"
          content="Fasilitas | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan Terencana di Timur Jakarta"
        />
        <meta
          property="og:description"
          content="Grand Wisata Bekasi menawarkan fasilitas seperti sekolah, rumah sakit, pasar modern, area kuliner, Water Park, Celebration Plaza, Club House, serta ruko-ruko dan lot komersial untuk melengkapi kebutuhan para penghuninya."
        />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <PageHeader bg={bannerImg}>Fasilitas</PageHeader>
        <section className="container">
          <p>{desc}</p>
          <FaCards datas={items} />
        </section>
        <PageFooter />
      </motion.div>
    </Layout>
  )
}

export const query = graphql`
  {
    facilitiesJson {
      desc
      headerImg {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allFacilityDetailsJson {
      nodes {
        icon
        items
        name
        slug
        thumb {
          childImageSharp {
            fluid(maxWidth: 6000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default index
