import { Link } from "gatsby"
import React from "react"
import "./faCard.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"

function Card({ data }) {
  return (
    <div className="col-md-3 col-6 mb-md-4">
      <Link className="fa-card card h-100" to={data.slug}>
        {/* <img src={data.img} className="card-img-top" alt="..."></img> */}
        <Img
          className="fa-card-img-top"
          fluid={data.thumb.childImageSharp.fluid}
          alt={data.name}
        />
        <div className="fa-card-body">
          {/* <h2 className="fa-card-number">{data.items.length}</h2> */}
          <h3 className="fa-card-title">{data.name}</h3>
          <div className="fa-card-btn">
            <FontAwesomeIcon icon={data.icon} className="icon" />
            <h2 className="fa-card-number">{data.items.length}</h2>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Card
